import { User } from "@/interfaces";
import {
  AccountBroker,
  SignInDto,
  SignInResponse,
  SignUpDto,
  SignUpResponse,
  authService,
  brokerService,
} from "@/services";
import { notification } from "antd";
import { jwtDecode } from "jwt-decode";
import { create } from "zustand";

export interface BrokerStore {
  name: string;
  address: string;
  phone: string;
  domain: string;
  subdomain: string;
  logo: string;
  email: string;
  country: string;
  locale: string;
}

interface IAuthValues {
  authenticated: boolean;
  loading: boolean;
  broker: BrokerStore | null;
  signUp: (signUpDto: SignUpDto) => Promise<SignUpResponse> | SignUpResponse;
  signIn: (signInDto: SignInDto) => Promise<SignInResponse> | SignInResponse;
  signInByToken: (token?: string) => Promise<void>;
  logOut: () => void;
  user: AccountBroker | null;
  setUser: (user: Partial<AccountBroker>) => void;
}

export const useAuthStore = create<IAuthValues>((set) => ({
  authenticated: false,
  loading: true,
  user: null,
  signIn: async (signInDto: SignInDto) => {
    const res = await authService.signIn(signInDto);

    if (res.success && res.token) {
      const _user = jwtDecode(res.token) as User;
      localStorage.setItem("auth", res.token);
      const [user, _broker] = await Promise.allSettled([
        brokerService.accounts.getOne(_user.account_id),
        brokerService.current(),
      ]);
      const broker = reflect(_broker);

      set({
        loading: false,
        authenticated: true,
        user: reflect(user, true),
        broker: broker
          ? {
              name: broker?.attributes.name,
              domain: broker?.attributes.domain,
              subdomain: broker?.attributes.subdomain,
              logo: broker?.attributes?.logo?.url,
              email: broker?.attributes.email,
              country: broker?.attributes?.country,
              locale: broker?.attributes?.locale,
              address: broker?.attributes?.address,
              phone: broker?.attributes?.phone,
            }
          : null,
      });
    }

    return res;
  },

  signUp: async (signUpDto: SignUpDto) => {
    const res = await authService.signUp(signUpDto);
    return res;
  },

  signInByToken: async (token) => {
    if (token) {
      try {
        const _user = jwtDecode(token) as User;
        const [user, _broker] = await Promise.allSettled([
          brokerService.accounts.getOne(_user.account_id),
          brokerService.current(),
        ]);
        const broker = reflect(_broker);
        set({
          loading: false,
          authenticated: true,
          user: reflect(user, true),
          broker: broker
            ? {
                name: broker?.attributes.name,
                domain: broker?.attributes.domain,
                subdomain: broker?.attributes.subdomain,
                logo: broker?.attributes?.logo?.url,
                email: broker?.attributes.email,
                country: broker?.attributes?.country,
                locale: broker?.attributes?.locale,
                address: broker?.attributes?.address,
                phone: broker?.attributes?.phone,
              }
            : null,
        });
      } catch (error) {
        console.log(error);
        notification.error({ message: "Hubo error al serializar el usuario" });
        set({
          authenticated: false,
          loading: false,
          user: null,
          broker: null,
        });
      }
    } else {
      set({ loading: false, authenticated: false, user: null, broker: null });
    }
  },
  logOut: () => {
    set({ authenticated: false, user: null, broker: null });
    localStorage.removeItem("auth");
  },
  setUser: (partialUser) => {
    set((state) => {
      if (!state.user) return { ...state };
      return { ...state, user: { ...state.user, ...partialUser } };
    });
  },
  broker: null,
}));

function reflect<T = any>(promise: PromiseSettledResult<T>, throwError = false, nameError = "") {
  if (promise.status === "fulfilled") {
    return promise.value as T;
  }
  if (throwError) {
    console.error(promise.reason);
    throw new Error(`Error al serializar la entidad ${nameError} ${promise.status}`);
  }
  return null;
}
