import {
  SidebarItem,
  SidebarSingleItem,
  useDataSidebar,
} from "@/layouts/DashboardLayout/data/data-sidebar";
import { useTranslation } from "react-i18next";
import Compressor from "compressorjs";
import { MessageInstance } from "antd/es/message/interface";
import { Property } from "@/interfaces";
import { ConfigPrintProperty } from "@/store/print-property";
import { ENV, formatter } from "./consts";
import axios from "axios";
import { ConfigPropertiesPdf } from "@/app/properties/properties-app/header-filters/ModalExportarPdfProperties";
import { AccountBroker } from "@/services";

export const translate = (translate: string) => useTranslation().t(translate);

interface CreateLibMessageProps {
  success: string;
  errors: {
    [value: string]: string;
  };
}

export const createLibMessages = ({
  success,
  errors,
}: CreateLibMessageProps) => ({
  success,
  errors,
});

interface GetMessageErrorProps {
  LibMessages: ReturnType<typeof createLibMessages>;
  errorKey?: string;
}

export const getMessageError = ({
  errorKey,
  LibMessages,
}: GetMessageErrorProps): string =>
  (errorKey && LibMessages["errors"][errorKey]) || "Error Fetching";

interface GetItemSideBar {
  path: string;
}
export const getItemSidebar = ({
  path,
}: GetItemSideBar): SidebarItem & SidebarSingleItem => {
  return useDataSidebar().find(
    (p) => p.type === "single" && p.path === path
  ) as SidebarItem & SidebarSingleItem;
};

export function coerceValue(val: any, type: any) {
  switch (type) {
    case "string":
      return String(val || (val === 0 ? 0 : ""));
    case "number":
      return val ? parseInt(val, 10) : val;
    case "float":
      return val ? parseFloat(val) : val;
    case "date":
      return val ? new Date(val) : val;
    case "boolean":
      return !!val;
    default:
      return val;
  }
}

export function isObject(v: any) {
  return v && typeof v === "object" && !Array.isArray(v);
}

export function paramsToObject(urlSearchParams: URLSearchParams) {
  const result = {} as any;
  const entries = urlSearchParams.entries();
  for (const [key, value] of entries) {
    result[key] = value;
  }

  return result;
}

export function sleep(ms = 2500) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export function compressImages(files: File[]): Promise<File[]> {
  //eslint-disable-next-line
  return new Promise(async function (resolve) {
    let images: File[] = [];
    let processedImages: number = 0;
    let numImagesToProcess: number = files.length;
    for (let i = 0; i < numImagesToProcess; i++) {
      const file = files[i];
      await new Promise((resolve) => {
        new Compressor(file, {
          quality: 0.6,
          resize: "contain",
          success(result) {
            //@ts-ignore
            images[i] = result;
            resolve(result);
          },
        });
      });
      processedImages += 1;
    }
    if (processedImages === numImagesToProcess) {
      resolve(images);
    }
  });
}

export function parseName(input: string) {
  let fullName = input || "";
  let result = {} as {
    name: string;
    lastName: string;
    secondLastName: string;
  };

  if (fullName.length > 0) {
    let nameTokens =
      fullName.match(
        /[A-ZÁ-ÚÑÜ][a-zá-úñü]+|([aeodlsz]+\s+)+[A-ZÁ-ÚÑÜ][a-zá-úñü]+/g
      ) || [];

    if (nameTokens.length > 3) {
      result.name = nameTokens.slice(0, 2).join(" ");
    } else {
      result.name = nameTokens.slice(0, 1).join(" ");
    }

    if (nameTokens.length > 2) {
      result.lastName = nameTokens.slice(-2, -1).join(" ");
      result.secondLastName = nameTokens.slice(-1).join(" ");
    } else {
      result.lastName = nameTokens.slice(-1).join(" ");
      result.secondLastName = "";
    }
  }

  return result;
}

export const randomId = () => Math.floor(new Date().valueOf() * Math.random());

// copy paste library 'flat'
export function flatten(target: any) {
  const opts: any = {};

  const delimiter = opts.delimiter || ".";
  const maxDepth = opts.maxDepth;
  const transformKey = opts.transformKey || keyIdentity;
  const output = {};

  function step(object: any, prev?: any, currentDepth?: any) {
    currentDepth = currentDepth || 1;
    Object.keys(object).forEach(function (key) {
      const value = object[key];
      const isarray = opts.safe && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isobject = type === "[object Object]" || type === "[object Array]";

      const newKey = prev
        ? prev + delimiter + transformKey(key)
        : transformKey(key);

      if (
        !isarray &&
        !isbuffer &&
        isobject &&
        Object.keys(value).length &&
        (!opts.maxDepth || currentDepth < maxDepth)
      ) {
        return step(value, newKey, currentDepth + 1);
      }

      //@ts-ignore
      output[newKey] = value;
    });
  }

  step(target);

  return output;
}

function isBuffer(obj: any) {
  return (
    obj &&
    obj.constructor &&
    typeof obj.constructor.isBuffer === "function" &&
    obj.constructor.isBuffer(obj)
  );
}

function keyIdentity(key: any) {
  return key;
}

export function slugId(id: number | string) {
  return `BL-${id}`;
}

export function parseSlugId(term: string) {
  return term.replaceAll("BL-", "");
}

interface PrintPropertyProps {
  message: MessageInstance;
  property: Property;
  broker: {
    name: string;
    domain: string;
    subdomain: string;
    logo: string;
    email: string;
  } | null;
  config?: ConfigPrintProperty;
}

let generatePDFPropertyDownloading = false;
let generatePDFPropertiesDownloading = false;

export async function printProperty({
  message,
  broker,
  property,
  config,
}: PrintPropertyProps) {
  if (generatePDFPropertyDownloading) return;
  try {
    generatePDFPropertyDownloading = true;
    toggleLoader2();

    const title = `${broker?.name || "BrokerLit"} - ${property.title} - ${
      property.location.label
    } ${formatter.format(
      property.retail_price.cents / 100,
      property.retail_price.currency_iso
    )}`;
    const req = await axios.post(
      `${ENV.MICROSERVICEGEN}/pdf/property`,
      {
        config: config || { showMap: true, showAgent: true },
        property: { ...property, broker },
      },
      {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
      }
    );
    const url = window.URL.createObjectURL(new Blob([req.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${title}.pdf`);
    document.body.appendChild(link);
    link.click();
    message.success({ content: "PDF Generado correctamente." });
  } catch (error) {
    console.log(error);
    message.error({ content: "Ha ocurrido un error generando el pdf." });
  } finally {
    toggleLoader2();
    generatePDFPropertyDownloading = false;
  }
}

interface PrintPDFProperties {
  message: MessageInstance;
  properties: Property[];
  broker: {
    name: string;
    domain: string;
    subdomain: string;
    logo: string;
    email: string;
  } | null;
  config: ConfigPropertiesPdf;
  account: AccountBroker | null;
}

export async function printPropertiesPDF({
  message,
  properties,
  broker,
  config,
  account,
}: PrintPDFProperties) {
  if (generatePDFPropertyDownloading) return;
  try {
    generatePDFPropertyDownloading = true;
    toggleLoader2();
    const title = `${broker?.name || "BrokerLit"} - Propiedades`;
    const req = await axios.post(
      `${ENV.MICROSERVICEGEN}/pdf/properties`,
      { properties, config, account },
      {
        responseType: "arraybuffer",
        headers: { Accept: "application/pdf" },
      }
    );
    const url = window.URL.createObjectURL(new Blob([req.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${title}.pdf`);
    document.body.appendChild(link);
    link.click();
    message.success({ content: "PDF Generado correctamente." });
  } catch (error) {
    console.log(error);
    message.error({ content: "Ha ocurrido un error generando el pdf." });
  } finally {
    toggleLoader2();
    generatePDFPropertyDownloading = false;
  }
}

export function sortDateFunction(a: string, b: string) {
  var dateA = new Date(a).getTime();
  var dateB = new Date(b).getTime();
  return dateA > dateB ? -1 : 1;
}

export function toggleLoader2() {
  const toggleLoader2 = document.getElementById("portal-loader-2");
  if (!toggleLoader2) return;
  if (toggleLoader2.classList.contains("hidden")) {
    toggleLoader2.classList.remove("hidden");
    toggleLoader2.classList.add("flex");
    return;
  }

  if (toggleLoader2.classList.contains("flex")) {
    toggleLoader2.classList.remove("flex");
    toggleLoader2.classList.add("hidden");
    return;
  }
}

interface IDefaultPromiseProps<T> {
  condition: boolean;
  service: Promise<T>;
}

export const defaultPromise = <T>(props: IDefaultPromiseProps<T>) => {
  const { condition, service } = props;
  if (!condition) return undefined;

  return service;
};

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
